import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'react-bootstrap';
import ExternalLink from './externalLink';

const Welcome = ({ siteTitle, classes, hasArrow }) => (
  <section id='welcome' className={classes}>
    <Container>
      <Row>
        <Col className='center-flex' xs='7' sm='7' md='7' lg='7'>
          <hr className='horizontalRule' />
          <h2>
            {' '}
            <em>BirthWork</em>: Thriving and Striving for Better Birth Outcomes  {' '}
          </h2>
          <p>Hosted by the Association of Maternal & Child Health Programs’ Women’s and Infant Health Team, <em>BirthWork</em> is your gateway to celebrating the continuous achievements of communities, professionals, practitioners and families in the journey to create safer and more equitable birthing experiences.  </p>
        </Col>
        <Col className='center-flex justify-center' xs='5' sm='5' md='5' lg='5'>
          {/* <ExternalLink href='https://www1.nyc.gov/office-of-the-mayor/news/149-22/mayor-adams-takes-action-reduce-maternal-infant-health-inequities-expanding-access-to#/0'>
            <button className='btn btn-primary large center-block'>
              LEARN MORE
            </button>
          </ExternalLink> */}
          <a href='/podcasts/birthwork/'>
            <button className='btn btn-primary large center-block'>
              LEARN MORE
            </button>
          </a>
        </Col>
      </Row>
    </Container>
    {hasArrow ? <div className='arrow-down'></div> : ''}
  </section>
);

Welcome.propTypes = {
  siteTitle: PropTypes.string.isRequired
};

Welcome.defaultProps = {
  siteTitle: ``
};

export default Welcome;
