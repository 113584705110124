import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ExternalLink from './ExternalLink.js';

const NewsBox = ({ post, hide }) => {
  //console.log(post);
  return (
    <section className={`news-post ${hide && 'hide'}`}>
      <Container>
        <Row>
          <Col className='center-flex' xs='12' sm='12' md='7' lg='7'>
            <p className='date'>
              <strong>{post.frontmatter.date}</strong>
            </p>
            <h2>
              {post.frontmatter.redirect ? (
                <ExternalLink href={post.frontmatter.redirect}>
                  <span class='ext-link'>{post.frontmatter.title}</span>
                </ExternalLink>
              ) : (
                <a href={post.frontmatter.slug}>{post.frontmatter.title}</a>
              )}
            </h2>
          </Col>
          <Col
            className='center-flex justify-center hidden-xs hidden-sm'
            xs='12'
            sm='12'
            md='5'
            lg='5'
          >
            {post.frontmatter.redirect ? (
              <ExternalLink href={post.frontmatter.redirect}>
                <button className='btn btn-secondary large center-block'>
                  LEARN MORE
                </button>
              </ExternalLink>
            ) : (
              <a href={post.frontmatter.slug}>
                <button className='btn btn-secondary large center-block'>
                  LEARN MORE
                </button>
              </a>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NewsBox;
