import React, { useEffect, useState } from 'react';
import LinkDisclaimer from './LinkDisclaimer';

const ExternalLink = (props) => {
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  useEffect(() => {
    if (props.showDisclaimer === true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [props.showDisclaimer]);

  return (
    <>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setShowDisclaimer(true);
        }}
        onKeyDown={() => setShowDisclaimer(true)}
      >
        {props.children}
      </span>
      <LinkDisclaimer
        showDisclaimer={showDisclaimer}
        setShowDisclaimer={setShowDisclaimer}
        disclaimerLink={props.href}
      />
    </>
  );
};

export default ExternalLink;
