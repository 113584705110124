import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import Banner from '../components/banner';
import SEO from '../components/seo';
import Welcome from '../components/welcome';
import NewsBox from '../components/newsBox';

import NewsSEOImage from '../assets/images/banner-homepage.jpg';

function handlePosts(edges) {
  const NewsArticles = edges
    .filter((edge) => !!edge.node.frontmatter.date)
    .map((edge) => <NewsBox key={edge.node.id} post={edge.node} />);

  return <div id='news'>{NewsArticles}</div>;
}

class News extends React.Component {
  render() {
    return (
      // <StaticQuery
      // query={graphql`
      //     query NewsMeta {
      //         site {
      //             siteMetadata {
      //                 title
      //                 description
      //             }
      //         }
      //         allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      //             edges {
      //                 node {
      //                     id
      //                     excerpt(pruneLength: 250)
      //                     frontmatter {
      //                         date(formatString: "MMMM DD, YYYY")
      //                         slug
      //                         title
      //                         featuredImage {
      //                             childImageSharp {
      //                                 fluid(maxWidth: 800) {
      //                                     ...GatsbyImageSharpFluid
      //                                 }
      //                             }
      //                         }
      //                         redirect
      //                     }
      //                 }
      //             }
      //         }
      //     }
      // `}
      // render={data => (
      //     <>
      //     <Layout page='news'>
      //         <SEO title='News' description='Read the latest about how the Safer Childbirth Cities initiative is powering progress for maternal health and birth equity.' image={NewsSEOImage} keywords={[``]} />
      //         <Banner title='News' subTitle={`Read the latest about how the Safer Childbirth Cities initiative is powering progress for maternal health and birth equity.`} titleColor='#FFFFFF' subTitleColor='#FFFFFF' hasArrow={true} reverse={true} />
      //         {handlePosts(data.allMarkdownRemark.edges)}
      //         <Welcome siteTitle={data.site.siteMetadata.title} classes='blueColorBkgd' />
      //     </Layout>
      //     </>
      // )}
      // />
      <Layout page='news'>
        <SEO
          title='News'
          description='Read the latest about how the Safer Childbirth Cities initiative is powering progress for maternal health and birth equity.'
          image={NewsSEOImage}
          keywords={[``]}
        />
        <Banner
          title='News'
          subTitle={`Read the latest about how the Safer Childbirth Cities initiative is powering progress for maternal health and birth equity.`}
          titleColor='#FFFFFF'
          subTitleColor='#FFFFFF'
          hasArrow={true}
          reverse={true}
        />
      </Layout>
    );
  }
}

export default News;
