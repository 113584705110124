import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'

class Banner extends React.Component {
    constructor(props) {
        super(props)

        this.title = this.props.title
        this.subTitle = this.props.subTitle
        this.titleColor = this.props.titleColor
        this.subTitleColor = this.props.subTitleColor
        this.image = this.props.image
        this.hasArrow = this.props.hasArrow
        this.reverse = this.props.reverse
    }

    render() {
        return (
            <section id='banner' className='internal' style={{background: `${this.image ? `url(${this.image}) fixed no-repeat center center` : `#2f91bc`}`, backgroundSize: `cover`}}>
                <Container fluid>
                    <Row>
                        <Col xs='12' sm='12' md='7' lg='7'>
                            <h1 className='text-center' style={{color: `${this.titleColor}`}}>{this.title}</h1>
                            <hr className='horizontalRule' />
                            <p className='text-center' style={{color: `${this.subTitleColor}`}}><em>{this.subTitle}</em></p>
                        </Col>
                    </Row>
                </Container>
                { this.hasArrow ? <div className={this.reverse ? 'arrow-down reverse' : 'arrow-down'} style={{background: `${this.image ? `url(${this.image}) fixed no-repeat center center` : `#2f91bc`}`, backgroundSize: `cover`}}></div> : '' }
            </section>
        )
    }
}

export default Banner